import wait from "@/javascript/components/tools/wait"

export function fadeOut (element, time) {
  const startOpacity = window.getComputedStyle(element, null).opacity
  time = time * startOpacity

  return Promise.resolve()
    .then(function() {
      element.style.setProperty('--transitionTime' , '0ms')
      element.style.setProperty('--targetOpacity' , startOpacity)
      element.classList.add('fade-transition')
    })
    .then(() => wait(1)) // wait for browser to set initial opacity
    .then(() => {
      element.style.setProperty('--transitionTime' , time + 'ms')
      element.style.setProperty('--targetOpacity' , 0)
    })
    .then(() => wait(time))
    .then(() => {
      element.classList.remove('fade-transition')
      element.style.setProperty('display', 'none')
      element.style.removeProperty('opacity')
      return element
    })
}

export function fadeIn (element, time, opts = {}) {
  const startOpacity = window.getComputedStyle(element, null).opacity
  time = time * (1 - startOpacity)

  return Promise.resolve()
    .then(function() {
      element.style.removeProperty('opacity')
      element.style.setProperty('display', opts.targetDisplay || 'block')
      element.style.setProperty('--transitionTime' , '0ms')
      element.style.setProperty('--targetOpacity' , startOpacity)
      element.classList.add('fade-transition') // start animation
    })
    .then(() => wait(1)) // wait for browser to set initial opacity
    .then(() => {
      element.style.setProperty('--transitionTime' , time + 'ms')
      element.style.setProperty('--targetOpacity' , 1)
    })
    .then(() => wait(time))
    .then(() => {
      element.classList.remove('fade-transition')
      return element
    })
}
